import React from "react";
import LayOuts from "../../LayOut";
import { makeStyles } from "@mui/styles";
import ActionButton from "../../components/ActionButton";
import AddInputFields from "../../components/AddInputField";
import StickyHeadTable from "../../components/StudentDataTable";

const useStyles = makeStyles((theme) => ({
  main_home: {
    border: "1px solid #213858",
    padding: "1rem",
    borderRadius: "0.5rem",
  },
}));

const HomePage = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  let styles = useStyles();
  return (
    <>
      <section style={{ padding: "40px 0px",height:"100vh" }}>
        <LayOuts>
          <div className={styles.main_home}>
            {open ? (
              <AddInputFields handleClickOpen={handleClickOpen} />
            ) : (
              <ActionButton handleClickOpen={handleClickOpen} />
            )}

            <StickyHeadTable />
          </div>
        </LayOuts>
      </section>
    </>
  );
};

export default HomePage;
