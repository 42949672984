import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Alert, Box, Button, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ApiResponseHandler, Login } from "../../MainApiServices";
import { AppContext } from "../../context/AppContextProvider";
import Spiner from "../../components/Spiner";

const useStyles = makeStyles((theme) => ({
  loginstyle: {
    position: "relative",
    height: "85vh",
  },
  formstyle: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& .form_section": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        maxWidth: "30rem",
        marginBottom: "3rem",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "15rem",
          paddingTop: "5rem",
          marginBottom: "2rem",
        },
      },
      "& form": {
        "& .MuiFormControl-root": {
          marginBottom: "2.5rem",
          [theme.breakpoints.down("sm")]: {
            minWidth: "32rem",
            maxWidth: "100%",
          },

          "& label": {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#213858",
          },
        },
        "& button": {
          padding: "0.8rem 0px",
          fontSize: "1.2rem",
        },
        "& .forget": {
          display: "flex",
          justifyContent: "space-between",
          "& a": {
            display: "block",
            padding: "1rem 0px",
            fontSize: "1.4rem",
            color: "#213858",
          },
        },
      },
      "& .error": {
        marginBottom: "3rem",
        "& div": {
          fontSize: "1.2rem",
        },
      },
    },
  },
}));

const defaultForm = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const [userData, setUserData] = useState(defaultForm);
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = useState();
  const navigate = useNavigate();
  const { openSnackbar,refreshPage } = React.useContext(AppContext);


  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    let queryString = window.location.search;
    // console.log("queryString in login", queryString);
    let response = await Login(userData, queryString);
    setLoading(false);
    let data = response ? response.data : null;

    if (data.additionalData.auth) {
      localStorage.setItem("user", JSON.stringify(data.result));
      localStorage.setItem("token", JSON.stringify(data.additionalData.auth));
      navigate("/");
      console.log(data, "inner");
      openSnackbar(["LogIn Successfully"], "success");
    }

    let responseType = ApiResponseHandler(data, {
      onSuccess: (result) => {
        setUserData(defaultForm);
        if (data.additionalData.auth) {
          localStorage.setItem("user", JSON.stringify(data.result));
          localStorage.setItem(
            "token",
            JSON.stringify(data.additionalData.auth)
          );
        }
        navigate("/");
        openSnackbar(["LogIn Successfully"], "success");
        refreshPage();
      },
      onFailed: () => {
        openSnackbar(["LogIn failed, try again..."], "error");
      },
    });
    console.log(responseType);
    setShowError(data.messages.email);
  };

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  let styles = useStyles();
  return (
    <>
      {loading ? (
        <Spiner />
      ) : (
        <section className={styles.loginstyle}>
          <div className={styles.formstyle}>
            <Box component="div" className="form_section">
              <img
                src="/assets/images/college-dishalogo.svg"
                alt="college disha"
              />
              {showError ? (
                <Alert variant="filled" severity="error" className="error">
                  {showError}
                </Alert>
              ) : (
                ""
              )}

              <form onSubmit={submitForm}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email Address"
                  name="email"
                  value={userData.email}
                  onChange={(event) => handleInputChange(event)}
                />
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={userData.password}
                  onChange={(event) => handleInputChange(event)}
                />
                <Button type="submit" fullWidth variant="contained">
                  {loading ? "Logging In..." : "Login"}
                </Button>
                <div className="forget">
                  <Link to="/register" variant="body2">
                    Create Account
                  </Link>
                  <Link to="/reset-password-email-verification" variant="body2">
                    Forget password?
                  </Link>
                </div>
              </form>
            </Box>
          </div>
        </section>
      )}
    </>
  );
};

export default LoginPage;
