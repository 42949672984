import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ApiResponseHandler, SendConfirmationMail } from "../MainApiServices";
import { AppContext } from "../context/AppContextProvider";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    "& .imageWrap": {
      width: 140,
      minHeight: 100,
      margin: "25px auto",
      "& img": {
        width: "100%",
      },
    },
    "& .heading": {
      color: "#33a0ab",
      fontSize: "2.6rem",
    },
    "& .subHeading": {
      margin: "10px 0",
      fontSize: 13,
      marginBottom: 0,
      textAlign: "center",
      "& span": {
        color: "#33a0ab",
      },
    },
    "& .resendHelperText": {
      fontSize: 10,
      marginTop: 10,
      color: "#33a0ab",
      "& span": {
        marginLeft: 5,
        cursor: "pointer",
        color: "#33a0ab",
      },
    },
  },
});

const MailSent = (props) => {
  const [email, setEmail] = useState(props.email);
  const { openSnackbar } = useContext(AppContext);

  const styles = useStyles();

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  const sendMail = async () => {
    console.log("sending mail");

    let response = await SendConfirmationMail(props.email);
    let data = response ? response.data : null;
    ApiResponseHandler(data, {
      onFailed: () => {
        openSnackbar(["sorry! something went wrong"]);
      },
      onUnAuthenticated: () => {
        openSnackbar(["You are not authorized"]);
      },
      onSuccess: () => {
        openSnackbar(["Mail sent sucessfully."], "success");
      },
      onError: (messages) => {
        openSnackbar([messages]);
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className="imageWrap">
        <img src="/assets/images/emailVerify.webp" alt="email-verify" />
      </div>

      <div className="formHead" style={{ textAlign: "center", padding: 0 }}>
        <Typography variant={"h1"} className={"heading"}>
          Confirm Your Email
        </Typography>
      </div>

      <Typography variant={"body1"} className={"subHeading"}>
        We have sent mail to <span>{email}</span> to confirm the validity of
        your email Id. After receiving the email follow the link provided to
        complete your registration
      </Typography>

      <Typography variant={"body1"} className={"resendHelperText"}>
        Haven't recieved yet? <span onClick={sendMail}>Resend</span>
      </Typography>
    </div>
  );
};

export default MailSent;
