import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/AppContextProvider";
import { ApiResponseHandler, ResetPassword } from "../../MainApiServices";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginstyle: {
    position: "relative",
    height: "85vh",
  },
  formstyle: {
    position: "absolute",
    top: "40%",
    left: "50%",
    boxShadow: "4px 7px 24px 0 #0000000f",
    borderRadius: "3rem",
    transform: "translate(-50%, -50%)",
    "& .form_section": {
      padding: "4rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& h2": {
        padding: "1rem 0px",
        fontSize: "2.6rem",
        color: "#213858",
      },
      "& p": {
        padding: "0px 0px 1rem 0px",
        fontSize: "1.4rem",
        color: "#213858",
      },
      "& form": {
        "& .MuiFormControl-root": {
          minWidth: "32rem",
          marginBottom: "2.5rem",
          "& label": {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#213858",
          },
        },
        "& button": {
          padding: "0.8rem 0px",
          fontSize: "1.2rem",
        },
      },
    },
  },
}));

const defaultForm = {
  password: "",
  confirmPassword: "",
};
const ResetPasswordPage = () => {
  const [userData, setUserData] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useContext(AppContext);
  const navigate = useNavigate();

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    let queryString = window.location.search;
    console.log("queryString in login", queryString);
    let response = await ResetPassword(userData, queryString);

    setLoading(false);
    console.log("Apply response", response);
    let data = response ? response.data : null;

    let responseType = ApiResponseHandler(data, {
      onSuccess: (result) => {
        setUserData(defaultForm);
        openSnackbar(["Password Changed Successfully"], "success");
        navigate("/login");
      },
      onFailed: () => {
        openSnackbar(["password Change Failed, try again..."], "error");
      },
    });
    console.log(responseType);
  };

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  let styles = useStyles();
  return (
    <>
      <section className={styles.loginstyle}>
        <div className={styles.formstyle}>
          <Box component="div" className="form_section">
            <Typography variant="h2">Reset Your Password</Typography>
            <Typography variant="body2">
              What would you like your new password to be?
            </Typography>
            <form onSubmit={submitForm}>
              <TextField
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                value={userData.password}
                onChange={(event) => handleInputChange(event)}
              />
              <TextField
                required
                fullWidth
                id="confirmPassword"
                label="Re Enter Password"
                name="confirmPassword"
                type="password"
                value={userData.confirmPassword}
                onChange={(event) => handleInputChange(event)}
              />
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
              >
                {loading ? "Changing..." : "Change password"}
              </Button>
            </form>
          </Box>
        </div>
      </section>
    </>
  );
};

export default ResetPasswordPage;
