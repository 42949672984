import { Alert, Dialog, Snackbar } from "@mui/material";
import { createContext, useState, useEffect } from "react";
import AlertBox from "../components/AlertBox.component";

export const AppContext = createContext({});

const AppContextProvider = (props) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [ModalVisible, setModalVisible] = useState(false);
  const [alertActionButton, setAlertActionButton] = useState(null);
  const [ModalMaxWidth, setModalMaxWidth] = useState("sm");
  const [ModalContent, setModalContent] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    errors: [],
    type: "error",
  });

  useEffect(() => {
    hideModal();
  }, []);

  const showAlert = (message, type, actionButton) => {
    setAlertMessage(message);
    setAlertActionButton(actionButton);
    setAlertType(type);
    setAlertVisible(true);
  };

  const hideAlert = () => {
    setAlertVisible(false);
    setAlertMessage("");
  };

  const showModal = (content, maxWidth = "sm") => {
    setModalContent(content);
    setModalMaxWidth(maxWidth);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
    setModalContent(null);
  };
  const closeSnakbar = () => {
    setSnackOpen(false);
  };

  const openSnackbar = (errors, type = "error") => {
    setSnackOpen(true);
    setErrorMessages({
      errors: errors,
      type: type,
    });
    setTimeout(() => {
      closeSnakbar();
    }, 5 * 1000);
  };
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    <>
      <AppContext.Provider
        value={{
          showAlert: showAlert,
          hideAlert: hideAlert,
          showModal: showModal,
          hideModal: hideModal,
          openSnackbar: openSnackbar,
          refreshPage:refreshPage
        }}
      >
        {props.children}

        <AlertBox
          show={alertVisible}
          type={alertType}
          message={alertMessage}
          actionButton={alertActionButton}
        />
        <Snackbar
          style={{ position: "fixed" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackOpen}
          autoHideDuration={10000}
        >
          <Alert onClose={closeSnakbar} severity={errorMessages.type}>
            {/* <AlertTitle>{errorMessages.type === 'success' ? 'Success' : 'Failed'}</AlertTitle> */}
            {errorMessages?.errors.map((message, index) => {
              return (
                <p key={index} style={{ lineHeight: "16px", fontSize: "14px" }}>
                  {message}
                </p>
              );
            })}
          </Alert>
        </Snackbar>
        <Dialog
          scroll="body"
          open={ModalVisible}
          onClose={hideModal}
          PaperProps={{ style: { borderRadius: "10px" } }}
          fullWidth
          maxWidth={ModalMaxWidth}
        >
          {ModalContent}
        </Dialog>
      </AppContext.Provider>
    </>
  );
};

export default AppContextProvider;
