import React, { useState, useEffect } from "react";
import LayOuts from "../../LayOut";
import { makeStyles } from "@mui/styles";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { GetSchoolProfileData } from "../../MainApiServices";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  ProfilePage: {
    padding: "2rem 0px 0px 0px",
    "& .profileImage": {
      position: "relative",
      "& img": {
        width: "100%",
        display: "block",
        height: "20rem",
        borderRadius: "1rem",
      },
      "& h1": {
        fontSize: "4rem",
        fontWeight: 900,
        color: "#213858",
        textStroke: "1px #fff",
        textShadow: "0px 4px 3px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-60%,-50%)",
        textTransform: "capitalize",
      },
    },
    "& .school_information": {
      boxShadow: "0px 0px 10px 5px #E9EEF2",
      padding: "2rem",
      borderRadius: "1rem",
      margin: "2rem auto",
      [theme.breakpoints.down("sm")]: {
        margin: "2rem 0px 0px auto",
        padding: "1rem",
      },
      "& .headingschoolName": {
        display: "flex",
        "& h2": {
          fontSize: "2rem",
          fontWeight: 600,
          color: "#213858",
          paddingBottom: "1.6rem",
        },
        "& span": {
          "& button": {
            padding: "0.5rem 0.8rem 0px 0px",
            "& svg": {
              fontSize: "1.8rem",
              fontWeight: 600,
              color: "#213858",
            },
          },
        },
      },

      "& h5": {
        fontSize: "1.2rem",
        fontWeight: 600,
        color: "#213858",
        textTransform: "capitalize",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.2rem",
        },
      },
      "& p": {
        fontSize: "1.8rem",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "1.4rem",
        width: "100%",
        textTransform: "capitalize",
        color: "#213858",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.4rem",
          padding: "1rem 0.6rem",
          borderRadius: "6px",
        },
      },
      "& .email_text": {
        textTransform: "lowercase",
      },
      "& .MuiGrid-item": {
        display: "flex",
        margin: "0.5rem 0px",
        alignItems: "center",
      },
    },
  },
}));

const ProfilePage = () => {
  const [profileData, setProfileData] = useState();
  const [loading, setLoading] = useState(false);

  let userLocalData = JSON.parse(localStorage.getItem("user"));

  const GetProfile = async () => {
    setLoading(true);
    let response = await GetSchoolProfileData({
      email: userLocalData.email,
    });
    setLoading(false);
    let data = response ? response?.data : null;
    if (data) {
      setProfileData(data.result);
    } else {
      setProfileData(null);
    }
  };

  useEffect(() => {
    GetProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let styles = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.ProfilePage}>
        <LayOuts>
          <div style={{ margin: "0px 0px 20px 0px" }}>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </div>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <div className="profileImage">
                <img src="/assets/images/school_image.webp" alt="schoolimage" />
                <Typography variant="h1">{userLocalData?.fullName}</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} sm={6} xs={12}>
              <div className="school_information">
                <div className="headingschoolName">
                  <span>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </span>
                  <Typography variant="h2">School Information</Typography>
                </div>
                <Grid container>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Name:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.companyName}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Number:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.companyNumber}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Email:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1" className="email_text">
                      {profileData?.companyEmail}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Location:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.location}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className="school_information">
                <div className="headingschoolName">
                  <span>
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                  </span>
                  <Typography variant="h2">Contact Person</Typography>
                </div>
                <Grid container>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Name:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">{profileData?.name}</Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Designation:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.designation}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Email:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1" className="email_text">
                      {profileData?.email}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Number:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.contactNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className="school_information">
                <div className="headingschoolName">
                  <span>
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                  </span>
                  <Typography variant="h2">Alternate Person</Typography>
                </div>
                <Grid container>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Name:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.alterateName}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Designation:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.alterateDesignation}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Email:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1" className="email_text">
                      {profileData?.alterateEmail}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Typography variant="h5">Number:</Typography>
                  </Grid>
                  <Grid item md={9} sm={9} xs={9}>
                    <Typography variant="body1">
                      {profileData?.alternateNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </LayOuts>
      </section>
    </>
  );
};

export default ProfilePage;
