import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let response = error.response;

    if (response?.status === 401 || 400) {
      return response;
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(
  function (request) {
    console.log("request --- ", request);
    return request;
  },
  function (error) {
    console.log("request error ---", error);
  }
);

export const BASE_URL = process.env.REACT_APP_BASEURL;
export const Domain = process.env.REACT_APP_API_DOMAIN;

const UserUrl = BASE_URL + "user/";
export const GetAccessTokenUrl = UserUrl + "token/refresh/";
const ProfileUrl = BASE_URL + "profile/";
const ProfileUpdateUrl = BASE_URL + "profile/update/";
const SubscriptionUrl = BASE_URL + "subscription/order/";
const SchoolUrl = BASE_URL + "school/";
const ApiRoutes = {
  RegisterUrl: SchoolUrl + "signup/",
  AddStudentrecardrUrl: SchoolUrl + "addstudent/",
  SchoolProfileUrl: SchoolUrl + "profile/",
  LoginUrl: UserUrl + "login/",
  LogoutUrl: UserUrl + "logout/",
  ChangePasswordUrl: UserUrl + "change_password/",
  SendResetPasswordMail: UserUrl + "send_confirmation_mail_school/",
  ResetPasswordUrl: UserUrl + "reset_password/",
  SendConfirmationEmailUrl: UserUrl + "send_confirmation_mail/",
  // ExportCsvUrl: SchoolUrl + "csv-export/",
  // ExportExelUrl: SchoolUrl + "xlsx-export/",
  ImportExelUrl: SchoolUrl + "excel/",
  DemoDownloadUrl: SchoolUrl + "addstudent/demo-excel/",
  RequestEmailConfirmationUrl: UserUrl + "get_email_flag/",
  EmailVerifyUrl: UserUrl + "verify_email/",
  UserActivitiesUrl: ProfileUrl + "activities/",
  ProfileUrl: ProfileUrl,
  ProfileUpdateUrl: ProfileUrl + "update/",
  ExperienceUpdateUrl: ProfileUpdateUrl + "experience/",
  EducationUpdateUrl: ProfileUpdateUrl + "education/",
  ProfileImageUpdateUrl: ProfileUpdateUrl + "image/",
  CoverImageUpdateUrl: ProfileUpdateUrl + "cover_image/",
  SubscriptionStatus: SubscriptionUrl + "subscription-status",
};

export const setHeader = (token, cookie) => {
  let header = { "Content-Type": "application/json" };

  if (token && token !== "unAuthenticated") {
    header["Authorization"] = `Bearer ${token}`;
  }
  if (cookie) {
    header["cookie"] = cookie;
  }
  console.log("headers----", header);
  return header;
};
const ApiService = {};
export const PostRequestObject = (data, token) => ({
  headers: setHeader(token),
  method: "POST",
  data: data,
  withCredentials: true,
});

export const PUTRequestObject = (data, token) => ({
  headers: setHeader(token),
  method: "PUT",
  data: data,
  withCredentials: true,
});

export const ApiResponseTypes = {
  RequestFailed: "__request_failed__",
  NotAuthenticated: "__not_authenticated__",
  RequestFailedError: "__request_failed_error__",
  dataNotFound: "__data_not_found__",
  RequestSuccess: "__request_success__",
};

export const ApiResponseHandler = (response, callbacks, statusCode) => {
  console.log("response object", response);

  console.log("status code is ", statusCode);
  if (statusCode) {
    // eslint-disable-next-line default-case
    switch (statusCode) {
      case 500:
        callbacks.onFailed && callbacks.onFailed();
        return ApiResponseTypes.RequestFailed;

      case 401:
        callbacks.onUnAuthenticated && callbacks.onUnAuthenticated();
        return ApiResponseTypes.NotAuthenticated;

      case 404:
        callbacks.onNoData && callbacks.onNoData();
        return ApiResponseTypes.dataNotFound;

      case 400:
        callbacks.onFailed && callbacks.onFailed();
        return ApiResponseTypes.RequestFailed;

      case 412:
        callbacks.onError && callbacks.onError(response?.messages);
        return ApiResponseTypes.RequestFailedError;

      case 200:
        callbacks.onSuccess && callbacks.onSuccess(response?.result);
        return ApiResponseTypes.RequestSuccess;
    }
  } else {
    if (response) {
      if (response.isAuthenticated || statusCode === 401) {
        if (response?.status) {
          const { result } = response;
          let keys = null;
          if (result) {
            keys = Object.keys(result);
          }

          let isResultPopulated = keys ? keys?.length : result?.length;
          if (isResultPopulated && statusCode !== 404) {
            callbacks.onSuccess && callbacks.onSuccess(response.result);
            return ApiResponseTypes.RequestSuccess;
          } else {
            callbacks.onNoData && callbacks.onNoData();
            return ApiResponseTypes.dataNotFound;
          }
        } else {
          callbacks.onError && callbacks.onError(response?.messages);
          return ApiResponseTypes.RequestFailedError;
        }
      } else {
        callbacks.onUnAuthenticated && callbacks.onUnAuthenticated();
        return ApiResponseTypes.NotAuthenticated;
      }
    } else {
      callbacks.onFailed && callbacks.onFailed();
      return ApiResponseTypes.RequestFailed;
    }
  }
};

export const Login = async (data, queryString) => {
  return await axios(ApiRoutes.LoginUrl + queryString, PostRequestObject(data))
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
};
export const Logout = async () => {
  return await axios(ApiRoutes.LogoutUrl, PostRequestObject({}))
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const Register = async (data) => {
  return await axios(ApiRoutes.RegisterUrl, PostRequestObject(data))
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const StudentAdd = async (data, id) => {
  return await axios(
    ApiRoutes.AddStudentrecardrUrl + `?id=${id}`,
    PostRequestObject(data)
  )
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
};

export const GetStudentData = async (id, search, selectValue) => {
  return await axios(
    ApiRoutes.AddStudentrecardrUrl +
      `?id=${id}&search=${search}&select=${selectValue}`,
    {
      headers: {
        Authorization: `bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    }
  )
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const GetSchoolProfileData = async ({ email }) => {
  return await axios(ApiRoutes.SchoolProfileUrl + `?email=${email}`, {
    headers: {
      Authorization: `bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  })
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const SendPasswordResetMail = async (email) => {
  return await axios(ApiRoutes.SendResetPasswordMail + `?email=${email}`)
    .then((response) => response)
    .catch((error) => console.log("error", error));
};
export const SendConfirmationMail = async (email) => {
  return await axios(ApiRoutes.SendConfirmationEmailUrl + `?email=${email}`, {})
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const ResetPassword = async (data, query) => {
  return await axios(
    ApiRoutes.ResetPasswordUrl + query,
    PostRequestObject(data)
  )
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const ImportExelFileData = async (data, email) => {
  return await axios(
    ApiRoutes.ImportExelUrl + `?email=${email}`,
    PostRequestObject(data)
  )
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const EditProfileInformation = async (data, email) => {
  return await axios(
    ApiRoutes.SchoolProfileUrl + `?email=${email}`,
    PostRequestObject(data)
  )
    .then((response) => response)
    .catch((error) => console.log("error", error));
};
export const ExecelDemo = async () => {
  return await axios(ApiRoutes.DemoDownloadUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export default ApiService;
