import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheck,
//   faExclamationTriangle,
//   faInfo,
//   faTimes,
// } from "@fortawesome/free-solid-svg-icons";
import { RequestStatusList } from "../ApiServices";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { faCheck, faExclamationTriangle, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  heading: {
    padding: 0,
    "& h2": {
      textTransform: "capitalize",
      padding: "10px 24px 0",
    },
  },
  icon: {
    width: 60,
    height: 60,
    margin: "20px auto 0px",
    borderRadius: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fontSize: 35,
    },
  },
  closeButton: {
    position: "absolute",
    width: 35,
    height: 35,
    textAlign: "center",
    lineHeight: "35px",
    right: 0,
    color: "red",
  },
});

function AlertBox(props) {
  const [type, setType] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(
    "Are You sure to continue this action?"
  );

  const styles = useStyles();

  useEffect(() => {
    if (props.type && props.type !== type) {
      setType(props.type);
    }
    if (props.message && props?.message !== message) {
      setMessage(props.message);
    }
    if (props.show !== show) {
      setShow(props.show);
    }
  }, [message, props, show, type]);

  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
        props.onClose && props.onClose();
      }}
    >
      <div
        className={styles.icon}
        style={{
          backgroundColor:
            type === RequestStatusList.Error
              ? "red"
              : type === RequestStatusList.Success
              ? "gree"
              : type === RequestStatusList.Warning
              ? "yellow"
              : "yellowgreen",
        }}
      >
        <FontAwesomeIcon
          icon={
            type === RequestStatusList.Error
              ? faTimes
              : type === RequestStatusList.Success
              ? faCheck
              : type === RequestStatusList.Warning
              ? faExclamationTriangle
              : faInfo
          }
          style={{
            color:
              type === RequestStatusList.Error
                ? "red"
                : type === RequestStatusList.Success
                ? "gree"
                : type === RequestStatusList.Warning
                ? "yellow"
                : "yellowgreen",
          }}
        />
      </div>

      <div
        className={styles.closeButton}
        onClick={() => {
          setShow(false);
          props.onClose && props.onClose();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>

      <DialogTitle className={styles.heading}>{type}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actionButton ? (
          props.actionButton
        ) : (
          <Button
            color="primary"
            onClick={() => {
              setShow(false);
              props.onClose && props.onClose();
            }}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AlertBox;
