import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import ProfilePage from "./pages/profile.js";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import MenuAppBar from "./components/header/NavBar";
import MarginTopComponent from "./components/MarginTopComponent";
import UserContextProvider from "./context/User.Context";
import ProtectedLayout from "./auth/ProtectedLayout";
import AppContextProvider from "./context/AppContextProvider";
import RegisterUser from "./pages/register";
import EmailVerification from "./pages/reset-password";
import ResetPasswordPage from "./pages/email-verification";
import EditProfile from "./pages/edit-profile";
import SearchApiProvider from "./context/SearchApi";
import CopyRightStrip from "./components/CopyRightStrip";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#213858",
    },
    secondary: {
      main: "#ba68c8",
      light: "#9c27b0",
      dark: "#7b1fa2",
    },
    error: {
      main: "#ef5350",
      light: "#d32f2f",
      dark: "#c62828",
    },
    warning: {
      main: "#ff9800",
      light: "#ed6c02",
      dark: "#e65100",
    },
    info: {
      main: "#03a9f4",
      light: "#0288d1",
      dark: "#01579b",
    },
    success: {
      main: "#00AB55",
      light: "#00AB55",
      dark: "#00AB55",
    },
  },
});

const App = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SearchApiProvider>
          <AppContextProvider>
            <UserContextProvider>
              <MenuAppBar />
              <MarginTopComponent />
              <Routes>
                <Route
                  path="/"
                  element={<ProtectedLayout Component={HomePage} />}
                />
                <Route
                  path="/profile"
                  element={<ProtectedLayout Component={ProfilePage} />}
                />
                <Route
                  path="/edit-profile"
                  element={<ProtectedLayout Component={EditProfile} />}
                />
                <Route
                  path="/reset-password-email-verification"
                  element={<EmailVerification />}
                />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/register" element={<RegisterUser />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </UserContextProvider>
          </AppContextProvider>
        </SearchApiProvider>s
      </ThemeProvider>
      <CopyRightStrip/>
    </>
  );
};

export default App;
