import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import LayOuts from "../../LayOut";
import {
  EditProfileInformation,
  GetSchoolProfileData,
} from "../../MainApiServices";
import { useContext } from "react";
import { AppContext } from "../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  loginstyle: {
    padding: "2rem 0px",
  },
  updateSection: {
    "& form": {
      "& .school_information": {
        boxShadow: "0px 0px 10px 5px #E9EEF2",
        padding: "2rem",
        borderRadius: "1rem",
        margin: "2rem auto",
        [theme.breakpoints.down("sm")]: {
          margin: "2rem 0px 0px auto",
          padding: "1rem",
        },
        "& .headingschoolName": {
          display: "flex",
          "& h2": {
            fontSize: "2rem",
            fontWeight: 600,
            color: "#213858",
            paddingBottom: "1.6rem",
          },
          "& span": {
            "& button": {
              padding: "0.5rem 0.8rem 0px 0px",
              "& svg": {
                fontSize: "1.8rem",
                fontWeight: 600,
                color: "#213858",
              },
            },
          },
        },
      },
      "& .MuiFormControl-root": {
        // marginBottom: "2.5rem",
        [theme.breakpoints.down("sm")]: {
          minWidth: "32rem",
          maxWidth: "100%",
        },
        "& label": {
          fontSize: "1rem",
          fontWeight: 600,
          color: "#213858",
        },
        "& input": {
          fontSize: "1.5rem",
          color: "#213858",
        },
      },
      "& .update_btn": {
        display: "block",
        maxWidth: "20rem",
        margin: "0px auto",
        "& button": {
          padding: "0.8rem 0px",
          fontSize: "1.2rem",
        },
      },
    },
  },
}));

const defaultForm = {
  companyName: "",
  companyNumber: "",
  location: "",
  name: "",
  email: "",
  designation: "",
  contactNumber: "",
  alterateName: "",
  alterateEmail: "",
  alterateDesignation: "",
  alternateNumber: "",
};

const UpdateUser = () => {
  const [updateForm, setUpdateForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useContext(AppContext);
  const navigate = useNavigate();

  let userLocalData = JSON.parse(localStorage.getItem("user"));
  const stringData = JSON.stringify(updateForm);

  const GetProfile = async () => {
    setLoading(true);
    let response = await GetSchoolProfileData({
      email: userLocalData.email,
    });
    setLoading(false);
    let data = response ? response?.data : null;
    if (data) {
      setUpdateForm(data.result);
    } else {
      setUpdateForm(null);
    }
  };

  useEffect(() => {
    GetProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitUpdateForm = async (event) => {
    event.preventDefault();
    let response = await EditProfileInformation(
      stringData,
      userLocalData.email
    );
    let data = response ? response.data : null;

    if (data) {
      setUpdateForm(defaultForm);
      openSnackbar(["Profile Update Successfully"], "success");
      navigate("/profile");
    } else {
      openSnackbar(["Profile Update, try again..."], "error");
    }
  };

  const handleInputChange = (e) => {
    setUpdateForm((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  let styles = useStyles();
  return (
    <>
      <section className={styles.loginstyle}>
        <LayOuts>
          <div className={styles.updateSection}>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <form onSubmit={submitUpdateForm}>
              <Grid container spacing={2}>
                {/* >>>>>>>>>>> School Information  >>>>>>>>>>> */}
                <Grid item md={6} sm={6} xs={12}>
                  <div className="school_information">
                    <div className="headingschoolName">
                      <span>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </span>
                      <Typography variant="h2">School Information</Typography>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          id="companyName"
                          label="School Name"
                          name="companyName"
                          value={updateForm.companyName}
                          onChange={(event) => handleInputChange(event)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="conpany_number"
                          label="Number"
                          name="companyNumber"
                          value={updateForm?.companyNumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="location"
                          label="Location"
                          name="location"
                          value={updateForm?.location}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        {/* <TextField
                          required
                          fullWidth
                          type="file"
                          id="image"
                          label="Image"
                          name="image"
                          value={updateForm?.companyImage}
                          // onChange={(e) => handleInputChange(e)}
                        /> */}
                        {/* <input
                          type="file"
                          name="image"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            setSelectedImage(event.target.files[0]);
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {/* >>>>>>>>>>> Contact Person  >>>>>>>>>>> */}

                <Grid item md={6} sm={6} xs={12}>
                  <div className="school_information">
                    <div className="headingschoolName">
                      <span>
                        <IconButton>
                          <PersonIcon />
                        </IconButton>
                      </span>
                      <Typography variant="h2">Contact Person</Typography>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="persion_name"
                          label="Name"
                          name="name"
                          value={updateForm?.name}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="email"
                          id="email"
                          label="Email"
                          name="email"
                          value={updateForm?.email}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          id="designation"
                          label="Designation"
                          name="designation"
                          value={updateForm?.designation}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="contactNumber"
                          label="Number"
                          name="contactNumber"
                          value={updateForm?.contactNumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {/* >>>>>>>>>>> Alternate Person  >>>>>>>>>>> */}

                <Grid item md={6} sm={6} xs={12}>
                  <div className="school_information">
                    <div className="headingschoolName">
                      <span>
                        <IconButton>
                          <PersonIcon />
                        </IconButton>
                      </span>
                      <Typography variant="h2">Alternate Person</Typography>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          //   required
                          fullWidth
                          type="text"
                          id="alterate_name"
                          label="Name"
                          name="alterateName"
                          value={updateForm?.alterateName}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          //   required
                          fullWidth
                          type="email"
                          id="alterate_email"
                          label="Email"
                          name="alterateEmail"
                          value={updateForm?.alterateEmail}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          //   required
                          fullWidth
                          type="text"
                          id="alterate_designation"
                          label="Designation"
                          name="alterateDesignation"
                          value={updateForm?.alterateDesignation}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          //   required
                          fullWidth
                          type="number"
                          id="alternate_number"
                          label="Number"
                          name="alternateNumber"
                          value={updateForm?.alternateNumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="update_btn">
                    <Button type="submit" fullWidth variant="contained">
                      Update
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </LayOuts>
      </section>
    </>
  );
};

export default UpdateUser;
