import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import { StudentAdd } from "../MainApiServices";
import { useContext } from "react";
import { AppContext } from "../context/AppContextProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  maininput: {
    margin: "1.5rem 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "1.5rem 0px",
    },
    "& form": {
      display: "flex",
      "& .input_area": {
        // display: "flex",
        "& input": {
          width: "100%",
          transition: "0.2s ease",
          padding: "0.8rem 1.2rem",
          minHeight: "4rem",
          border: "1px solid #a5a5a5",
          borderRadius: "4px",
          "&:hover": {
            border: "1px solid #213858",
            borderRadius: "4px",
          },
          "&:focus": {
            border: "1px solid #213858!important",
            borderRadius: "4px",
            outline: "none",
          },
        },
        "& .quickbtn": {
          display: "flex",
          "& .quickbtnadd": {
            width: "50%",
            margin: "0 auto",
            display: "block",
            verticalAlign: "middle",
            marginTop: "15px",
            padding: "0.8rem 1rem",
            minHeight: "4rem",
          },
          "& .backbtn": {
            width: "30%",
            height: "40px",
            margin: "0 auto",
            marginTop: "15px",
            background: "#040D36",
          },
        },
      },
      "& label": {
        fontSize: "1.2rem",
        padding: "0px 1rem",
        color: "#213858",
        fontWeight: 600,
      },
      "& button": {
        fontSize: "1.2rem",
        padding: "0px 1rem",
        textTransform: "capitalize",
      },
    },
  },
}));
const defaultForm = {
  student_name: "",
  student_class: "",
  guardian_name: "",
  guardian_number: "",
  guardian_email: "",
};

const AddInputFields = ({ handleClickOpen }) => {
  const [studentDataAdd, setStudentDataAdd] = useState(defaultForm);
  const [loading] = useState(false);
  const { openSnackbar } = useContext(AppContext);

  let userLocalData = JSON.parse(localStorage.getItem("user"));

  const submitForm = async (event) => {
    event.preventDefault();
    let response = await StudentAdd(studentDataAdd, userLocalData.id);
    let data = response ? response.data : null;

    if (data) {
      openSnackbar(["Added Successfully"], "success");
      setStudentDataAdd(defaultForm);
      handleClickOpen();
    } else {
      openSnackbar(["Application failed, try again..."], "error");
    }
  };

  const handleInputChange = (e) => {
    setStudentDataAdd((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  let styles = useStyles();
  return (
    <>
      <div className={styles.maininput}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <label htmlFor="sname">Student Name *</label>
                <input
                  type="text"
                  id="sname"
                  required
                  name="student_name"
                  placeholder="Student Name"
                  value={studentDataAdd.student_name}
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <label htmlFor="sclass">Student Class *</label>
                <input
                  type="text"
                  id="sclass"
                  required
                  name="student_class"
                  placeholder="Student Class"
                  value={studentDataAdd.student_class}
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <label htmlFor="gname">Guardian Name *</label>
                <input
                  type="text"
                  id="gname"
                  required
                  name="guardian_name"
                  placeholder="Guardian Name"
                  value={studentDataAdd.guardian_name}
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <label htmlFor="gemail">Guardian Email *</label>
                <input
                  type="email"
                  id="gemail"
                  required
                  name="guardian_email"
                  placeholder="Guardian Email"
                  value={studentDataAdd.guardian_email}
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <label htmlFor="gnumber">Guardian Number *</label>
                <input
                  type="number"
                  id="gnumber"
                  required
                  name="guardian_number"
                  placeholder="Guardian Number"
                  value={studentDataAdd.guardian_number.slice(0, 10).toString()}
                  onChange={(event) => handleInputChange(event)}
                />
              </div>
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <div className="input_area">
                <div className="quickbtn">
                  <Button
                    variant="contained"
                    type="submit"
                    className="quickbtnadd"
                  >
                    {loading ? "Adding..." : "Quick Add"}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleClickOpen()}
                    startIcon={<ArrowBackIcon />}
                    className="backbtn"
                    title="Back"
                  ></Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default AddInputFields;
