import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  margin_top: {
    marginTop: "65px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
    [theme.breakpoints.down("xs")]: {
        marginTop: "48px",
      },
  },
}));

const MarginTopComponent = () => {
  let styles = useStyles();
  return <div className={styles.margin_top}></div>;
};

export default MarginTopComponent;
