import React from "react";
import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { SearchApiContext } from "../context/SearchApi";
import { Button, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    "& form": {
      "& select": {
        marginTop: "10px",
        width: "100%",
        minWidth: "150px",
        height: "38px",
        border: "1px solid rgba(33, 56, 88, 0.5)",
        borderRadius: "4px",
        outline: "none",
        padding: "10px 5px",
        marginRight: "10px",
        background: "#fff",
        color: "#213858",
        fontSize: "14px",
        transition: "0.5s all",
        "&:hover": {
          border: "1px solid #213858",
        },
        "& option": {
          fontSize: "14px",
        },
      },
      "& input": {
        marginTop: "10px",
        width: "100%",
        height: "38px",
        border: "1px solid rgba(33, 56, 88, 0.5)",
        borderRadius: "4px",
        outline: "none",
        padding: "10px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "0px",
        },
        "&:hover": {
          border: "1px solid #213858",
        },
      },
      "& ::placeholder": {
        color: "#213858",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
  },
}));

const SearchComponents = () => {
  const { handleInputChange, handleSearch } = useContext(SearchApiContext);

  let styles = useStyles();
  return (
    <div className={styles.dropdown}>
      <form onSubmit={handleSearch}>
        <Grid container spacing={1}>
          <Grid item md={5} sm={5} xs={12}>
            <select
              name="select"
              onChange={(event) => handleInputChange(event)}
            >
              <option value="">Select:</option>
              <option value="studentName">Student Name</option>
              <option value="studentClass">Student Class</option>
              <option value="guardianName">Guardian Name </option>
              <option value="guardianEmail">Guardian Email</option>
              <option value="guardianNumber">Guardian Number</option>
            </select>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <input
              type="text"
              required
              name="search"
              placeholder="search...."
              onChange={(event) => handleInputChange(event)}
              // value={searchInput.search}
            />
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="buttonstyle">
              <Button variant="contained" type="submit">
                Search
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SearchComponents;
