import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { AppContext } from "../../context/AppContextProvider";
import {
  ApiResponseHandler,
  SendPasswordResetMail,
} from "../../MainApiServices";
import MailSent from "../../components/MailSent.Components";

const useStyles = makeStyles((theme) => ({
  loginstyle: {
    position: "relative",
    height: "85vh",
  },
  formstyle: {
    position: "absolute",
    top: "40%",
    left: "50%",
    boxShadow: "4px 7px 24px 0 #0000000f",
    borderRadius: "3rem",
    transform: "translate(-50%, -50%)",
    "& .form_section": {
      padding: "4rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& h2": {
        padding: "1rem 0px",
        fontSize: "2.6rem",
        color: "#213858",
      },
      "& p": {
        padding: "0px 0px 1rem 0px",
        fontSize: "1.4rem",
        color: "#213858",
      },
      "& form": {
        "& .MuiFormControl-root": {
          minWidth: "32rem",
          marginBottom: "2.5rem",
          "& label": {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#213858",
          },
        },
        "& button": {
          padding: "0.8rem 0px",
          fontSize: "1.2rem",
        },
      },
    },
  },
}));
const defaultForm = {
  email: "",
};

const EmailVerification = () => {
  const [userData, setUserData] = useState(defaultForm);
  const [loading, setLoading] = React.useState(false);
  const { openSnackbar, showModal } = React.useContext(AppContext);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    let queryString = window.location.search;
    console.log("queryString in login", queryString);
    let response = await SendPasswordResetMail(userData.email);
    setLoading(false);
    console.log("Apply response", response);
    let data = response ? response.data : null;

    let responseType = ApiResponseHandler(data, {
      onSuccess: (result) => {
        setUserData(defaultForm);
        showModal(
          <div style={{ padding: 20 }}>
            <MailSent email={userData.email} />
          </div>
        );
      },
      onFailed: () => {
        openSnackbar(["LogIn failed, try again..."], "error");
      },
    });
    console.log(responseType);
  };

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  let styles = useStyles();
  return (
    <>
      <section className={styles.loginstyle}>
        <div className={styles.formstyle}>
          <Box component="div" className="form_section">
            <Typography variant="h2">Verify Email</Typography>
            <Typography variant="body2">
              Verify your email to reset password
            </Typography>
            <form onSubmit={submitForm}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={userData.email}
                onChange={(event) => handleInputChange(event)}
              />
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Box>
        </div>
      </section>
    </>
  );
};

export default EmailVerification;
