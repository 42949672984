import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import {  useContext } from "react";
import { SearchApiContext } from "../context/SearchApi";

const columns = [
  { id: "id", label: "S.Id", minWidth: 50 },
  { id: "sname", label: "Student Name", minWidth: 120 },
  {
    id: "sclass",
    label: "Student Class",
    minWidth: 120,
  },
  {
    id: "gname",
    label: "Guardian Name",
    minWidth: 120,
  },
  {
    id: "gmail",
    label: "Guardian Email ",
    minWidth: 120,
  },
  {
    id: "gnumber",
    label: "Guardian Number",
    minWidth: 120,
  },
  { id: "assesment", label: "Assessment Test", minWidth: 100 },
  { id: "subscriptionstatus", label: "Subscription Status", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  wraper: {
    margin: "3.5rem 0px 0px 0px",
  },
  tablecontainer: {
    maxHeight: "40rem",
    // border: "1px solid #213858",
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            fontSize: "1.4rem",
            color: "#213858",
            fontWeight: "600",
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            fontSize: "1.4rem",
            color: "#213858",
            fontWeight: "500",
          },
        },
      },
    },
  },
}));

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { studentDataGet } =
    useContext(SearchApiContext);
  let styles = useStyles();

  // let emptyRows =
  //   page > 0
  //     ? Math.max(0, (1 + page) * rowsPerPage - result.schoolData.length)
  //     : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };
console.log(studentDataGet,"studentDataGet")
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className={styles.wraper}>
      <TableContainer className={styles.tablecontainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentDataGet?.result
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                let {
                  studentName,
                  studentClass,
                  guardianName,
                  guardianEmail,
                  guardianNumber,
                  assessmentTest,
                  subscript,
                } = item;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell key={item.id} align={item.align}>
                      {index + 1}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {studentName}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {studentClass}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {guardianName}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {guardianEmail}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {guardianNumber}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {assessmentTest}
                    </TableCell>
                    <TableCell key={item.id} align={item.align}>
                      {subscript}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 15, 20, 50, 100, 250, 500]}
        count={parseInt(studentDataGet?.result?.length) || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<span style={{ fontSize: "1.2rem" }}>Row: </span>}
        labelDisplayedRows={({ page }) => {
          return <span style={{ fontSize: "1.2rem" }}>Page:{page + 1}</span>;
        }}
        backIconButtonProps={{ color: "primary" }}
      />
    </Paper>
  );
}
