import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ApiResponseHandler, Register } from "../../MainApiServices";
import { Storages } from "../../ApiServices";
import { AppContext } from "../../context/AppContextProvider";
import { useState } from "react";
import { useContext } from "react";
import Spiner from "../../components/Spiner";

const useStyles = makeStyles((theme) => ({
  loginstyle: {
    position: "relative",
    height: "80vh",
  },
  formstyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& .form_section": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "50rem",
      "& img": {
        maxWidth: "30rem",
        marginBottom: "6rem",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "15rem",
          marginBottom: "1.5rem",
        },
      },
      "& form": {
        "& .MuiFormControl-root": {
          marginBottom: "2.5rem",
          [theme.breakpoints.down("sm")]: {
            minWidth: "32rem",
            maxWidth: "100%",
          },

          "& label": {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#213858",
          },
        },
        "& button": {
          padding: "0.8rem 0px",
          fontSize: "1.2rem",
        },
        "& .forget": {
          textAlign: "end",
          "& a": {
            display: "block",
            padding: "1rem 0px",
            fontSize: "1.4rem",
            color: "#213858",
          },
        },
        "& .MuiGrid-root": {
          paddingTop: "0px !important",
        },
      },
    },
  },
}));

const defaultForm = {
  name: "",
  email: "",
  password: "",
  confirm_password: "",
  passcode: "",
};

const RegisterUser = () => {
  const [userRegister, setUserRegister] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { openSnackbar } = useContext(AppContext);

  const submitRegisterForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    let response = await Register(userRegister);
    setLoading(false);
    let data = response ? response.data : null;
    let responseType = ApiResponseHandler(data, {
      onUnAuthenticated: () => {
        openSnackbar(["Authentication failed, try again..."], "error");
      },
      onSuccess: (result) => {
        sessionStorage.setItem(Storages.registerEmail, userRegister.email);
        setUserRegister(defaultForm);
        openSnackbar(["Registered Successfully"], "success");
        // navigate("/");
      },
      onFailed: () => {
        openSnackbar(["Registration failed, try again..."], "error");
      },
    });
    console.log(responseType);
  };

  const handleInputChange = (e) => {
    setUserRegister((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  // console.log(userData);

  let styles = useStyles();
  return (
    <>
      {loading ? (
        <Spiner />
      ) : (
        <section className={styles.loginstyle}>
          <div className={styles.formstyle}>
            <Box component="div" className="form_section">
              <img
                src="/assets/images/college-dishalogo.svg"
                alt="college disha"
              />

              <form onSubmit={submitRegisterForm}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="text"
                      id="name"
                      label="School Name"
                      name="name"
                      value={userRegister.name}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="email"
                      id="email"
                      label="Email Address"
                      name="email"
                      value={userRegister.email}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={userRegister.password}
                      autoComplete="current-password"
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                      id="cpassword"
                      value={userRegister.confirm_password}
                      autoComplete="current-password"
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="passcode"
                      label="Passcode"
                      type="password"
                      id="passcode"
                      value={userRegister.passcode}
                      autoComplete="current-password"
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Button type="submit" fullWidth variant="contained">
                      {loading ? "Signing In..." : "SignUp"}
                    </Button>
                  </Grid>
                </Grid>

                <div className="forget">
                  <Link to="/login" variant="body2">
                    If you already have an account, just login
                  </Link>
                </div>
              </form>
            </Box>
          </div>
        </section>
      )}
    </>
  );
};

export default RegisterUser;
