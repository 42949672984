import { createContext, useEffect, useState } from "react";
import { GetStudentData } from "../MainApiServices";
const defaultForm = {
  select: "",
  search: "",
};
export const SearchApiContext = createContext();
const SearchApiProvider = (props) => {
  const [studentDataGet, SetstudentDataGet] = useState();
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(defaultForm);
  let userLocalData = JSON.parse(localStorage.getItem("user"));

  const handleInputChange =  (e) => {
    setSearchInput((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {}, []);

  const SearchQuery = async () => {
    setLoading(true);
    let response = await GetStudentData(
      userLocalData?.id,
      searchInput?.search,
      searchInput?.select
    );
    setLoading(false);
    let data = response ? response?.data : null;
    if (data.result !== "") {
      SetstudentDataGet(data);
    } else {
      SetstudentDataGet(null);
    }
  };
  useEffect(() => {
    SearchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    SearchQuery();
  };

  return (
    <div>
      <SearchApiContext.Provider
        value={{
          studentDataGet: studentDataGet,
          searchInput: searchInput,
          handleInputChange: handleInputChange,
          handleSearch: handleSearch,
        }}
      >
        {props.children}
      </SearchApiContext.Provider>
    </div>
  );
};

export default SearchApiProvider;
