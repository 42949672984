import { CircularProgress } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main_div: {
    position: "relative",
    height: "100vh",
    width: "100vw",
    "& .mid_div": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
}));

const Spiner = () => {
  let styles = useStyles();

  return (
    <>
      <div className={styles.main_div}>
        <div className="mid_div">
          <CircularProgress />
        </div>
      </div>
    </>
  );
};

export default Spiner;
