import { ApiResponseHandler } from "./MainApiServices";

export const ValidateFields = (conditions, refs, callback = null) => {
  let fields = Object.keys(conditions);

  fields?.forEach((field, index) => {
    if (conditions[field]) {
      refs[field].makeDirty();
      callback && callback(field, true);
    } else {
      callback && callback(field, false);
    }
  });
};

export const ValidateMobile = (num, checkLength = true) => {
  let res = { isError: false, message: "" };

  if (!num) {
    res.isError = true;
    res.message = "Mobile Number is required";
  } else if (parseInt(num[0]) < 5) {
    res.isError = true;
    res.message = "Enter a valid number";
  } else if (num.length > 10) {
    res.isError = true;
    res.message = "Number must be of 10 digits";
  } else if (checkLength) {
    if (num?.length !== 10) {
      res.isError = true;
      res.message = "Number must be of 10 digits";
    }
  } else {
    res.isError = false;
    res.message = "";
  }
  return res;
};

export const Storages = {
  Token: "token",
  User: "user",
  Navigation: "navigation",
  AccessToken: "accessToken",
  refreshToken: "refreshToken",
  UserId: "userId",
  registerEmail: "registerEmail",
  lastPage: "lastPage",
  Auth: "auth",
};

export const getUser = () => {
  let user = localStorage.getItem(Storages.User);
  // console.log(user);
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const StoreToken = async (token) => {
  localStorage.setItem(Storages.Token, JSON.stringify(token));
};

export const clearStore = async (field) => {
  localStorage.removeItem(field);
};

export const StoreUser = async (data) => {
  let user = JSON.stringify(data);
  localStorage.setItem(Storages.User, user);
};

export const ErrorTypes = {
  UNVERIFIED_EMAIL: "UNVERIFIED_EMAIL",
  USER_ALREADY_EXIST: "USER_ALREADY_EXISTS",
};

export const RequestStatusList = {
  Error: "error",
  Success: "success",
  Warning: "warning",
  Info: "info",
};

//---------- The function to set last navigation state -------\\

export const setLastNavigation = async (navigation) => {
  let item = JSON.stringify(navigation);
  try {
    localStorage.setItem(Storages.Navigation, item);
  } catch (error) {
    console.log("storage navigation setting error", error);
    return false;
  }
  return true;
};

//---------- The function to get last navigation state -------\\

export const getLastNavigation = () => {
  let navigation = localStorage.getItem(Storages.Navigation);
  if (navigation) {
    return JSON.parse(navigation);
  } else {
    return false;
  }
};

//---------- The function to set cookie -------\\
export function SetCookie(cname, cvalue, exdays = 5) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/login";
}

//---------- The function to remove cookie -------\\
export function DeleteCookie(cname) {
  var d = new Date();
  d.setTime(d.getTime() + 0);
  var expires = "expires=" + d.toUTCString();
  // eslint-disable-next-line no-useless-concat
  document.cookie = cname + "=" + ";" + expires + ";path=/login";
}

//---------- The function to get cookie -------\\
export function GetCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//---------- The function to get data for initial page render -------\\
export function GetPageInitialData(responseData, status) {
  let responseType = ApiResponseHandler(
    responseData,
    {},
    status ? status : null
  );
  console.log("response data", responseData);
  console.log("response Type in get initial data", responseType);
  if (responseData) {
    return {
      result: responseData?.result,
      responseType: responseType,
      messages: responseData?.messages ?? null,
    };
  } else {
    return {
      result: null,
      responseType: responseType,
      messages: responseData?.messages ?? null,
    };
  }
}
