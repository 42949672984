import React, { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  clearStore,
  DeleteCookie,
  getUser,
  SetCookie,
  Storages,
  StoreUser,
} from "../ApiServices";
import {
  ApiResponseHandler,
  GetAccessTokenUrl,
  Logout,
} from "../MainApiServices";
import { AppContext } from "./AppContextProvider";

const defaultState = {
  user: null,
  logout: () => {},
  setUser: () => {},
  token: null,
  loading: true,
  showUnverifyStrip: () => {},
  loggingOut: false,
  setToken: () => {},
};

export const UserContext = createContext(defaultState);

const UserContextProvider = (props) => {
  const [user, setUser] = useState(defaultState.user);
  const [Token, setToken] = useState(defaultState.token);
  const [loading, setLoading] = useState(defaultState.loading);
  const [loggingOut, setLoggingOut] = useState(defaultState.loggingOut);
  const [userDetails, setUserDetails] = useState([]);
  const tokenRefreshInterval = useRef(null);
  const { openSnackbar } = React.useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUserDetails(userData);
    }
  }, []);

  const get_User = () => {
    setLoading(true);
    let user = getUser();
    setUser(user);
    setLoading(false);
    return user;
  };

  const AsyncTokenRefresh = async () => {
    let user = get_User();
    TokenHandler(user);
  };

  useEffect(() => {
    if (!userDetails) {
      AsyncTokenRefresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshToken = async (_user) => {
    let response = await fetch(GetAccessTokenUrl, {
      withCredentials: true,
    })
      .then((responseJson) => responseJson.json())
      .catch((error) => console.log("refresh error", error));
    console.log("refreshed token", response);

    ApiResponseHandler(response, {
      onSuccess: (result) => {
        console.log("refreshed token", response);
        setToken(result);
      },
      onUnAuthenticated: () => {
        setToken("unAuthenticated");
      },
    });
  };

  const TokenHandler = (_user = user) => {
    setLoading(true);
    console.log("refreshing tokens");
    console.log("user id", _user?.id);
    setLoading(false);
    if (_user?.id) {
      refreshToken(_user);
    }
    tokenRefreshInterval.current = setInterval(() => {
      if (_user?.id) {
        refreshToken(_user);
      }
    }, 5 * 60 * 1000);
  };

  const updateUser = (user, storeLocally = true) => {
    if (storeLocally) {
      StoreUser(user);
      SetCookie(Storages.UserId, user?.id, 30 * 3);
    }
    console.log("user", user);
    setUser(user);
  };

  const removeUser = () => {
    clearStore(Storages.Token);
    clearStore(Storages.User);
    DeleteCookie(Storages.Auth);
    DeleteCookie(Storages.UserId);
    setUser(null);
  };

  const storeToken = (token) => {
    setToken(token);
    // SetCookie(Storages.AccessToken, token, 1);
  };

  const logout = async () => {
    setLoggingOut(true);
    let AxiosResponse = await Logout();
    setLoggingOut(false);
    let data = AxiosResponse ? AxiosResponse?.data : null;
    ApiResponseHandler(data, {
      onFailed: () => {
        openSnackbar(["Logout Failed", "try again"]);
      },
      onError: (messages) => {
        openSnackbar([messages?.logout]);
      },
      onUnAuthenticated: () => {
        removeUser();
        clearInterval(tokenRefreshInterval.current);
        openSnackbar(["Logged out Successfully"], "success");
        navigate("/login");
      },
      onSuccess: () => {
        removeUser();
        clearInterval(tokenRefreshInterval.current);
        openSnackbar(["Logged out Successfully"], "success");
        navigate("/login");
      },
    });
  };
  return (
    <>
      <UserContext.Provider
        value={{
          user: user,
          logout: logout,
          setUser: updateUser,
          token: Token,
          loading: loading,
          loggingOut: loggingOut,
          setToken: storeToken,
        }}
      >
        {props.children}
      </UserContext.Provider>
    </>
  );
};

export default UserContextProvider;
