import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { UserContext } from "../../context/User.Context";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appbar: {
    position: "static",
    boxShadow: "5px 5px 25px 0 #aeaec033 !important",
  },
  toolbar: {
    padding: "0px 6% !important",
    "& a": {
      flexGrow: 1,
      fontSize: "2rem",
      textTransform: "capitalize",
      fontWeight: 600,
      textDecoration: "none",
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    "& .profileSection": {
      "& button": {
        "& svg": {
          fontSize: "4rem",
          [theme.breakpoints.down("sm")]: {
            fontSize: "3rem",
          },
        },
      },
    },
  },
  menuInner: {
    top: "36px !important",
    "& ul": {
      minWidth: "150px",
      "& li": {
        fontSize: "16px",
        color: "#213858",
      },
    },
  },
}));

function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = React.useContext(UserContext);
  const navigate = useNavigate();

  let userLocalData = JSON.parse(localStorage.getItem("user"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutUser = () => {
    logout();
    handleClose();
  };

  const logoInUser = () => {
    navigate("/login");
    handleClose();
  };
  const viewProfile = () => {
    navigate("/profile");
    handleClose();
  };
  const EditProfile = () => {
    navigate("/edit-profile");
    handleClose();
  };

  let styles = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={styles.appbar}>
        <Toolbar className={styles.toolbar}>
          <Link to="/">{userLocalData?.fullName}</Link>
          <div className="profileSection">
            <IconButton
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={styles.menuInner}
            >
              {userLocalData == null ? (
                <div>
                  <MenuItem onClick={() => logoInUser()}>Login</MenuItem>
                </div>
              ) : (
                <div>
                  <MenuItem onClick={() => viewProfile()}>Profile</MenuItem>
                  <MenuItem onClick={() => EditProfile()}>
                    Edit Profile
                  </MenuItem>
                  <MenuItem onClick={() => logoutUser()}>Logout</MenuItem>
                </div>
              )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MenuAppBar;
