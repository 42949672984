import { Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { makeStyles } from "@mui/styles";
import { CSVLink } from "react-csv";
import { ExecelDemo, ImportExelFileData } from "../MainApiServices";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AppContext } from "../context/AppContextProvider";
import SearchComponents from "./Search.Components";
import { SearchApiContext } from "../context/SearchApi";

const useStyles = makeStyles((theme) => ({
  mainactionbutton: {
    "& .buttonstyle": {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
      },

      "& button": {
        minWidth: "7rem",
        maxHeight: "4rem",
        margin: "1rem 0.5rem",
        textTransform: "capitalize",
        fontSize: "1.4rem",
        [theme.breakpoints.down("sm")]: {
          minWidth: "100% !important",
          margin: "1rem auto",
        },
      },
      "& .drop_btn": {
        position: "relative",
        zIndex: 999,
        [theme.breakpoints.down("sm")]: {
          minWidth: "100% !important",
        },
        "& button": {
          minWidth: "7rem",
          maxHeight: "4rem",
          margin: "1rem 0.5rem",
          textTransform: "capitalize",
          fontSize: "1.4rem",
          [theme.breakpoints.down("sm")]: {
            margin: "auto",
          },
        },
        "& .inner_drop_btn": {
          position: "absolute",
          [theme.breakpoints.down("sm")]: {
            minWidth: "100% !important",
          },
          "& button": {
            minWidth: "100%",
            maxHeight: "4rem",
            margin: "0.3rem 0.5rem",
            [theme.breakpoints.down("sm")]: {
              margin: "0.3rem auto",
            },
            "& a": {
              color: "#fff !important",
            },
          },
        },
      },
    },
  },
}));

const ActionButton = ({ handleClickOpen }) => {
  const fileName = "student-detail";
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { openSnackbar, refreshPage } = useContext(AppContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { studentDataGet } = useContext(SearchApiContext);
  const ref = useRef();

  let userLocalData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    let response = await ImportExelFileData(formData, userLocalData.email);
    let data = response ? response.data : null;
    console.log(data.messages, "responecheck");
    if (data.messages === "") {
      openSnackbar(["Upload succesfull"], "success");
      setFile();
    } else {
      openSnackbar(["Upload failed, try again..."], "error");
    }
    setTimeout(() => {
      refreshPage();
    }, 2000);
  };

  let styles = useStyles();

  return (
    <>
      <section className={styles.mainactionbutton}>
        <Grid container>
          <Grid item md={5} sm={12} xs={12} className="buttonstyle">
            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleClickOpen}
            >
              New Contact
            </Button>
            <ExelUploadPopUp handleSubmit={handleSubmit} setFile={setFile} />
            <div className="drop_btn" ref={ref}>
              <Button
                variant={isMenuOpen ? "contained" : "outlined"}
                startIcon={<ImportExportIcon />}
                onClick={() => setIsMenuOpen((oldState) => !oldState)}
              >
                Export Csv/Xlsx
              </Button>
              {isMenuOpen && (
                <div className="inner_drop_btn">
                  <Button variant="contained" startIcon={<ImportExportIcon />}>
                    <CSVLink
                      data={studentDataGet?.result}
                      filename={fileName}
                      style={{ textDecoration: "none", color: "#213858" }}
                    >
                      {loading ? "Loading csv..." : "Export Csv"}
                    </CSVLink>
                  </Button>
                  <ExportToExcel
                    apiData={studentDataGet?.result}
                    fileName={fileName}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={7} sm={12} xs={12}>
            <SearchComponents />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ActionButton;

// ******** ExportExcel ********//

export const ExportToExcel = ({ apiData, fileName }) => {
  const [loading] = useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="contained"
      startIcon={<ImportExportIcon />}
      onClick={(e) => exportToCSV(apiData, fileName)}
    >
      {loading ? "Loading xlsx..." : "Export xlsx"}
    </Button>
  );
};

// ******** Import ExelData ********//\

const PoUPuseStyles = makeStyles((theme) => ({
  maiSection: {
    "& h3": {
      color: "#213858",
      textAlign: "center",
      marginTop: "20px",
      fontFamily: "gordita-bold",
    },
    "& form": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      minWidth: 350,
      "& .submit_btn": {
        "& button": {
          fontWeight: 600,
          minHeight: "38px",
          minWidth: "110px",
          textTransform: "capitalize",
          fontFamily: "gordita-bold",
          fontSize: "14px",
          marginLeft: "10px",
        },
      },
    },
    "& .demo_section": {
      padding: "10px 20px 20px 20px",
      "& h5": {
        color: "#213858",
        textAlign: "center",
        fontFamily: "gordita-bold",
        fontSize: "26px",
        paddingBottom: "10px",
      },
      "& img": {
        width: "100%",
      },
      "& a": {
        display: "block",
        textAlign: "end",
        color: "#213858",
        fontFamily: "serif",
        marginTop: "10px",
        fontSize: "14px",
      },
    },
  },
}));

const ExelUploadPopUp = ({ handleSubmit, setFile }) => {
  const [open, setOpen] = React.useState(false);
  const [urlData, setUrlData] = useState([]);
  let styles = PoUPuseStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDemoUrl = async () => {
    let response = await ExecelDemo();
    let data = response ? response.data : null;
    if (data.result !== "") {
      setUrlData(data.result);
    } else {
      setUrlData(null);
    }
  };

  useEffect(() => {
    getDemoUrl();
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<ImportExportIcon />}
      >
        Import Xlsx
      </Button>

      <Dialog open={open} onClose={handleClose} className={styles.maiSection}>
        <Typography variant="h3">Upload Excel File</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="file"
            required
            onChange={(e) => setFile(e.target.files[0])}
          />
          <div className="submit_btn">
            <Button variant="contained" type="submit">
              Upload
            </Button>
          </div>
        </form>
        <div className="demo_section">
          <Typography variant="h5">Excel Demo</Typography>
          <img src="/assets/images/sample_exel.jpg" alt=" dummy_exel" />
          <a href={urlData?.file}>Downlod Excel File</a>
        </div>
      </Dialog>
    </>
  );
};
