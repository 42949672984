import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px 6%",
  },
}));

const LayOuts = (props) => {
  let styles = useStyles();
  return (
    <>
      <div className={styles.container}>{props.children}</div>
    </>
  );
};

export default LayOuts;
