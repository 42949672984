import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  copyRight: {
    backgroundColor: "#213858",
    borderTop: "1px solid #777",
    padding: "10px 0",
    textAlign: "center",
    fontSize: 13,
    color: "#fff",
    width: "100%",
    flexShrink: 0,
    "& p": {
      marginBottom: 0,
      fontSize: 12,
      "& a": {
        color: "#fff",
        fontSize: 12,
        textDecoration: "none",
      },
    },
  },
}));

const CopyRightStrip = () => {
  const [CurrentYear, setCurrentYear] = useState(2021);

  const setYear = () => {
    let date = new Date();
    let year = date.getFullYear();
    setCurrentYear(year);
  };
  useEffect(() => {
    setYear();
  }, []);

  let styles = useStyles();

  return (
    <>
      <div className={styles.copyRight}>
        <p>
          Copyright @{CurrentYear}.
          <a
            target="_blank"
            href="https://www.collegedisha.com"
            rel="noreferrer"
          >
            www.collegedisha.com
          </a>
          . All rights reserved
        </p>
      </div>
    </>
  );
};

export default CopyRightStrip;
